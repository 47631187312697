<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getCurrentCategoryQuery",
  
  watch: {
    currentCategory() {
      this.$store.commit('graphqlData', { item: 'currentCategory', result: this.currentCategory });
      this.$store.commit('graphqlData', { item: 'seo', result: this.currentCategory?.category?.seo });
     },
  },
    
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL
    });
    
    const variables = reactive({
        slug: computed(() => store.state.data.categorySlug),
        lang: computed(() => store.state.lang.currentLang)
    });
    
    const getCurrentCategoryQuery = gql`
      query MyQuery($slug: String!, $lang:Locale!) {
          category(where: {slug: $slug}, locales: [$lang]) {
            name
            seo {
              metaDescription
              metaTitle
            }
          }
        }
    `;
    
    const { data: currentCategory } = useQuery({
      query: getCurrentCategoryQuery,
      variables
    });
    
    return { currentCategory };
  },
}
</script>