<template>
<div></div></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryCategoryImages',

  watch: {
    categoryImages() {
      this.$store.commit('graphqlData', { item: 'categoryImages', result: this.categoryImages });
    },
  },

  setup() {
    const store = useStore();

    const variables = reactive({
      slug: computed(() => store.state.data.categorySlug),
    });

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
    });

    const queryCategoryImages = gql`
      query MyQuery ($slug: String!) {
        items(orderBy: order_ASC, where: {category: {slug: $slug}}) {
          images(first: 1) {
            url(transformation: {document: {output: {format: webp}}, image: {resize: {width: 1000}}})
          }
        }
      }
    `;

    const { data: categoryImages } = useQuery({
      query: queryCategoryImages,
      variables,
    });

    return { categoryImages };
  },
};
</script>
