<template>
<div></div>
</template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryCategoryItems',

  watch: {
    categoryItems() {
      this.$store.commit('graphqlData', { item: 'categoryItems', result: this.categoryItems });
    },
  },

  setup() {
    const store = useStore();

    const variables = reactive({
      slug: computed(() => store.state.data.categorySlug),
      lang: computed(() => store.state.lang.currentLang),
    });

    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
    });

    const queryCategoryItems = gql`
      query MyQuery ($slug: String!, $lang:Locale!) {
        items(orderBy: order_ASC, where: {category: {slug: $slug}}, locales: [$lang]) {
          id
          name
          subtext
          slug
          tags
        }
      }
    `;

    const { data: categoryItems } = useQuery({
      query: queryCategoryItems,
      variables,
    });

    return { categoryItems };
  },
};
</script>
